:root {
	--container-width: 1340px;
	--grid-basis: 16px;
	--header-height: 92px;
	--burger-color: #fff;
	--burger-color-active: #fff;
	--body-color: #3b3014;
	--body-bg-color: #fffbef;
	--color-black: #1a191b;
	--color-white: #ffffff;
	--color-accent: #0cc493;
	--color-validation: #ff2d61;
	--color-body: var(--color-black);
	--color-body-link: var(--color-black);
	--color-video-accent: var(--brand100);
	--font-primary: 'Arimo', sans-serif;
	--font-secondary: 'Playfair Display', serif;
	--spinner-color: var(--brand100);
}

:global(.screen-large) {
	--header-height: 120px;
}

::selection {
	background: var(--brand70);
	color: #000;
}

* {
	box-sizing: border-box;
}

html,
body,
input,
select,
textarea {
	font-size: 16px;
}

body {
	margin: 0;
	font-display: swap;
	background: var(--body-bg-color);
}

body,
input,
textarea,
button {
	font-family: var(--font-primary);
	color: var(--color-body);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}
